import React from "react"
import Layout from "../components/layout.js"
import Nav from "../components/nav.js"
import Content from "../components/content.js"
import SEO from "../components/seo.js"
import "./text-audio.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Publikationen" />
    <Nav />
    <p className="ueberschrift">Alle Publikationen</p>
    <Content />
  </Layout>
)

export default IndexPage
